import type { LocaleCode, LocaleConfig } from '@/types';

export const DEFAULT_LOCALE = 'en' as const;
export const SUPPORTED_LOCALES = [
    'en',
    'de',
    'en-GB',
    'en-CA',
    'en-AU',
] as const;

export const DOMAINS = {
    'thumbgrab.com': 'en',
    'thumbgrab.de': 'de',
    'thumbgrab.co.uk': 'en-GB',
    'thumbgrab.ca': 'en-CA',
    'thumbgrab.com.au': 'en-AU',
} as const satisfies Record<string, LocaleCode>;

export const LOCALES: Record<LocaleCode, LocaleConfig> = {
    en: {
        code: 'en',
        domain: 'thumbgrab.com',
        name: 'English',
        default: true,
        direction: 'ltr',
        disabled: true,
    },
    de: {
        code: 'de',
        domain: 'thumbgrab.de',
        name: 'Deutsch',
        direction: 'ltr',
    },
    'en-GB': {
        code: 'en-GB',
        domain: 'thumbgrab.co.uk',
        name: 'English (UK)',
        fallback: 'en',
        direction: 'ltr',
    },
    'en-CA': {
        code: 'en-CA',
        domain: 'thumbgrab.ca',
        name: 'English (Canada)',
        fallback: 'en',
        direction: 'ltr',
        disabled: true,
    },
    'en-AU': {
        code: 'en-AU',
        domain: 'thumbgrab.com.au',
        name: 'English (Australia)',
        fallback: 'en',
        direction: 'ltr',
        disabled: true,
    },
} as const;

import { useState, useRef } from 'react';
import { DEFAULT_LOCALE, LOCALES, DOMAINS } from '@/i18n/config';
import { translations } from '@/i18n/translations';
import type { LocaleCode, Translations } from '@/types';

function getTranslationsForLocale(locale: LocaleCode): Translations {
    if (translations[locale]) {
        return translations[locale];
    }

    const fallbackLocale = LOCALES[locale]?.fallback;
    if (fallbackLocale && translations[fallbackLocale]) {
        return translations[fallbackLocale];
    }

    return translations[DEFAULT_LOCALE];
}

function detectLocaleFromDomain(hostname: string): LocaleCode {
    const sortedDomains = Object.entries(DOMAINS).sort(
        ([a], [b]) => b.length - a.length
    );

    for (const [domain, locale] of sortedDomains) {
        if (hostname.includes(domain)) {
            return locale;
        }
    }

    return DEFAULT_LOCALE;
}

export function useTranslations(
    initialTranslations?: Translations
): Translations {
    const initializedRef = useRef(false);

    const [translations] = useState<Translations>(() => {
        if (initialTranslations) {
            return initialTranslations;
        }

        if (typeof window === 'undefined') {
            return getTranslationsForLocale(DEFAULT_LOCALE);
        }

        if (!initializedRef.current) {
            initializedRef.current = true;

            try {
                const hostname = window.location.hostname.toLowerCase();
                const detectedLocale = detectLocaleFromDomain(hostname);
                return getTranslationsForLocale(detectedLocale);
            } catch (error) {
                console.warn(
                    'Error detecting locale from domain:',
                    error instanceof Error ? error.message : 'Unknown error'
                );
                return getTranslationsForLocale(DEFAULT_LOCALE);
            }
        }

        return getTranslationsForLocale(DEFAULT_LOCALE);
    });

    return translations;
}

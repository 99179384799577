import { en } from '@/i18n/translations/en';
import type { Translations } from '@/types';

export const enGB: Translations = {
    ...en,
    meta: {
        title: 'YouTube Thumbnail Download UK - Download Thumbnails HD Free',
        description:
            "Download YouTube thumbnails in HD quality (1280x720). UK's free YouTube thumbnail downloader - instant download, no signup required.",
        keywords: [
            'youtube thumbnail download',
            'youtube thumbnail downloader',
            'youtube thumbnail grabber',
            'download youtube thumbnail',
            'save youtube thumbnail',
        ],
        ogTitle: 'YouTube Thumbnail Download UK - HD Quality Free Download',
        ogDescription:
            "Download YouTube thumbnails in HD quality. UK's best free thumbnail downloader for YouTube videos.",
    },
    structuredData: {
        ...en.structuredData,
        abstract:
            "UK's free online tool to download YouTube video thumbnails in HD quality",
        description:
            "Download YouTube video thumbnails in HD quality (1280x720). The UK's preferred thumbnail downloader - free, fast, and no registration required.",
    },
};

import type { Translations } from '@/types';

export const de: Translations = {
    meta: {
        title: 'YouTube Thumbnail downloaden - HD Qualität & kostenlos | Thumbgrab',
        description:
            'YouTube Thumbnails herunterladen in HD (1280x720). Kostenloses Tool zum direkten Download von YouTube Thumbnails - keine Anmeldung nötig.',
        keywords: [
            'youtube thumbnail downloaden',
            'youtube thumbnail herunterladen',
            'youtube thumbnail download',
            'youtube vorschaubild downloaden',
            'youtube miniatur downloaden',
        ],
        ogTitle: 'YouTube Thumbnail downloaden & herunterladen - HD Qualität',
        ogDescription:
            'Lade YouTube-Video-Thumbnails in HD-Qualität (1280x720) herunter. Schnell, kostenlos, keine Registrierung erforderlich.',
    },
    home: {
        title: 'YouTube Thumbnail Downloader',
        subtitle: 'HD-Qualität • Keine Anmeldung • Kostenlos',
        aria: {
            mainContent: 'YouTube-Thumbnail-Downloader-Anwendung',
        },
    },
    youtube: {
        errors: {
            invalidUrl: 'Ungültiges YouTube-URL-Format',
            invalidVideoId: 'Ungültiges Video-ID-Format',
            fetchFailed: 'Thumbnails konnten nicht abgerufen werden',
            urlRequired: 'URL ist erforderlich',
        },
    },
    form: {
        placeholder: 'YouTube-URL hier einfügen',
        button: {
            default: 'Herunterladen',
            loading: 'Lädt...',
        },
        errors: {
            fetchFailed:
                'Thumbnails können derzeit nicht abgerufen werden, bitte versuche es erneut',
        },
        aria: {
            form: 'Thumbnail-Download-Formular',
            urlInput: 'YouTube-URL-Eingabeformular',
            urlField: 'YouTube-URL eingeben',
            clearButton: 'Eingabefeld löschen',
            submitButton: 'Thumbnail herunterladen',
            loadingButton: 'Download wird verarbeitet',
            errorMessage: 'Fehlermeldung',
        },
    },
    thumbnail: {
        preview: {
            download: '{quality} Thumbnail herunterladen',
        },
        aria: {
            preview: 'Vorschau des YouTube-Video-Thumbnails',
            section: 'Thumbnail-Vorschau und Download-Bereich',
            loadingState: 'Lade Thumbnail-Vorschau',
            downloadButton: '{quality}-Qualitäts-Thumbnail herunterladen',
        },
    },
    ads: {
        aria: {
            placement: 'Werbeplatzierung',
            topAd: 'Obere Werbung',
            leftSidebarAd: 'Linke Seitenleisten-Werbung',
            rightSidebarAd: 'Rechte Seitenleisten-Werbung',
        },
    },
    offline: {
        title: 'Du bist offline',
        message:
            'Bitte überprüfe deine Internetverbindung und versuche es erneut.',
        retry: 'Verbindung wiederherstellen',
    },
    error: {
        default: {
            title: 'Etwas ist schiefgegangen',
            description:
                'Wir haben den Fehler protokolliert und werden ihn untersuchen.',
            retry: 'Erneut versuchen',
        },
        notFound: {
            title: 'Seite nicht gefunden',
            description:
                'Entschuldigung, wir konnten die gesuchte Seite nicht finden. Bitte überprüfe die URL oder kehre zur Startseite zurück.',
            backHome: 'Zurück zur Startseite',
        },
        aria: {
            errorPage: 'Fehlerseite',
            retryButton: 'Aktion wiederholen',
            errorMessage: 'Fehlermeldung',
        },
    },
    manifest: {
        appName: 'Thumbgrab - YouTube Thumbnail Downloader',
        shortName: 'Thumbgrab',
        description:
            'Kostenloses Online-Tool zum Herunterladen von YouTube-Video-Thumbnails in HD-Qualität',
        downloadAction: 'Thumbnail herunterladen',
        downloadDescription:
            'Starten Sie den Download eines YouTube-Thumbnails',
        screenshots: {
            desktop: 'Desktop-Ansicht von Thumbgrab',
            mobile: 'Mobile Ansicht von Thumbgrab',
        },
    },
    structuredData: {
        appName: 'Thumbgrab - YouTube Thumbnail Downloader',
        abstract:
            'Kostenloses Online-Tool zum Herunterladen von YouTube-Video-Thumbnails in HD-Qualität',
        description:
            'Laden Sie YouTube-Video-Thumbnails in HD-Qualität (1280x720), SD und HQ-Auflösung herunter. Kostenloses Online-Tool, keine Registrierung erforderlich.',
    },
};

import type { Translations } from '@/types';

export const en: Translations = {
    meta: {
        title: 'Download YouTube Thumbnails HD - Instant Free Download | Thumbgrab',
        description:
            'Download YouTube thumbnails instantly in HD (1280x720). No signup, no watermarks - just paste URL and download. Free forever.',
        keywords: [],
        ogTitle: 'YouTube Thumbnail Downloader - Download HD Thumbnails Free',
        ogDescription:
            'Download YouTube video thumbnails in HD quality (1280x720). Fast, free, no registration required.',
    },
    home: {
        title: 'YouTube Thumbnail Downloader',
        subtitle: 'HD Quality • No Signup • Free',
        aria: {
            mainContent: 'YouTube thumbnail downloader application',
        },
    },
    youtube: {
        errors: {
            invalidUrl: 'Invalid YouTube URL format',
            invalidVideoId: 'Invalid video ID format',
            fetchFailed: 'Failed to fetch thumbnails',
            urlRequired: 'URL is required',
        },
    },
    form: {
        placeholder: 'Enter YouTube URL here',
        button: {
            default: 'Download',
            loading: 'Loading...',
        },
        errors: {
            fetchFailed: 'Unable to get thumbnails right now, please try again',
        },
        aria: {
            form: 'Thumbnail download form',
            urlInput: 'YouTube URL input form',
            urlField: 'Enter YouTube URL',
            clearButton: 'Clear input field',
            submitButton: 'Download thumbnail',
            loadingButton: 'Processing download',
            errorMessage: 'Error message',
        },
    },
    thumbnail: {
        preview: {
            download: 'Download {quality} Thumbnail',
        },
        aria: {
            preview: 'YouTube video thumbnail preview',
            section: 'Thumbnail preview and download section',
            loadingState: 'Loading thumbnail preview',
            downloadButton: 'Download {quality} quality thumbnail',
        },
    },
    ads: {
        aria: {
            placement: 'Advertisement placement',
            topAd: 'Top advertisement',
            leftSidebarAd: 'Left sidebar advertisement',
            rightSidebarAd: 'Right sidebar advertisement',
        },
    },
    offline: {
        title: "You're Offline",
        message: 'Please check your internet connection and try again.',
        retry: 'Retry Connection',
    },
    error: {
        default: {
            title: 'Something went wrong',
            description: "We've logged the error and will look into it.",
            retry: 'Try again',
        },
        notFound: {
            title: 'Page not found',
            description:
                "Sorry, we couldn't find the page you're looking for. Please check the URL or return to homepage.",
            backHome: 'Back to Home',
        },
        aria: {
            errorPage: 'Error page',
            retryButton: 'Retry action',
            errorMessage: 'Error notification',
        },
    },
    manifest: {
        appName: 'Thumbgrab - YouTube Thumbnail Downloader',
        shortName: 'Thumbgrab',
        description:
            'Free online tool to download YouTube video thumbnails in HD quality',
        downloadAction: 'Download Thumbnail',
        downloadDescription: 'Start downloading a YouTube thumbnail',
        screenshots: {
            desktop: 'Desktop view of Thumbgrab',
            mobile: 'Mobile view of Thumbgrab',
        },
    },
    structuredData: {
        appName: 'Thumbgrab - YouTube Thumbnail Downloader',
        abstract:
            'Free online tool to download YouTube video thumbnails in HD quality',
        description:
            'Download YouTube video thumbnails in HD quality (1280x720), SD, and HQ resolutions. Free online tool, no registration required.',
    },
};

import { en } from '@/i18n/translations/en';
import type { Translations } from '@/types';

export const enCA: Translations = {
    ...en,
    meta: {
        title: 'YouTube Thumbnail Downloader Canada - Download HD Free',
        description:
            "Download YouTube thumbnails in HD (1280x720). Canada's fastest YouTube thumbnail downloader - free download, no registration needed.",
        keywords: [
            'youtube thumbnail download',
            'youtube thumbnail downloader',
            'download youtube thumbnail',
            'download youtube video thumbnail',
            'youtube thumbnail downloader online',
        ],
        ogTitle: 'YouTube Thumbnail Downloader Canada - Download HD Free',
        ogDescription:
            "Download YouTube thumbnails in HD quality. Canada's preferred free thumbnail downloader.",
    },
    structuredData: {
        ...en.structuredData,
        abstract:
            "Canada's free online tool to download YouTube video thumbnails in HD quality",
        description:
            "Download YouTube video thumbnails in HD (1280x720). Canada's most reliable thumbnail downloader - free and instant download.",
    },
};

import { de } from '@/i18n/translations/de';
import { en } from '@/i18n/translations/en';
import { enAU } from '@/i18n/translations/en-AU';
import { enCA } from '@/i18n/translations/en-CA';
import { enGB } from '@/i18n/translations/en-GB';
import type { LocaleCode, Translations } from '@/types';

export const translations: Record<LocaleCode, Translations> = {
    en,
    de,
    'en-GB': enGB,
    'en-CA': enCA,
    'en-AU': enAU,
} as const;

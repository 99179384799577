'use client';

import { Button } from '@/components/ui/button';
import type { Translations } from '@/types';

interface ErrorPageProps {
    title: string;
    description: string;
    actionLabel?: string;
    onAction?: () => void;
    showAction?: boolean;
    translations: Translations;
}

export function ErrorPage({
    title,
    description,
    actionLabel = 'Try again',
    onAction,
    showAction = true,
    translations: t,
}: ErrorPageProps) {
    return (
        <main
            className="mx-auto flex min-h-dvh w-full max-w-7xl flex-col items-center justify-center bg-background px-4 py-8 sm:px-6 lg:px-8"
            aria-label={t.error.aria.errorPage}
        >
            <div
                className="w-full max-w-prose space-y-6 text-center"
                role="alert"
                aria-label={t.error.aria.errorMessage}
                aria-live="assertive"
            >
                <h1 className="text-4xl font-bold tracking-tight text-foreground sm:text-5xl">
                    {title}
                </h1>

                <p className="mx-auto max-w-[50ch] text-base text-muted-foreground sm:text-lg">
                    {description}
                </p>

                {showAction && onAction && (
                    <Button
                        onClick={onAction}
                        variant="outline"
                        className="min-w-40"
                        aria-label={t.error.aria.retryButton}
                    >
                        {actionLabel}
                    </Button>
                )}
            </div>
        </main>
    );
}

import { en } from '@/i18n/translations/en';
import type { Translations } from '@/types';

export const enAU: Translations = {
    ...en,
    meta: {
        title: 'Download YouTube Thumbnails HD Australia - Free Downloader',
        description:
            "Download YouTube thumbnails in HD quality (1280x720). Australia's fastest thumbnail downloader - free download, no registration.",
        keywords: [
            'youtube thumbnail download',
            'youtube thumbnail downloader',
            'download youtube thumbnail',
            'youtube thumbnail downloader online',
            'save youtube thumbnail',
        ],
        ogTitle: 'YouTube Thumbnail Download Australia - HD Quality Free',
        ogDescription:
            "Download YouTube thumbnails in HD quality. Australia's preferred free thumbnail downloader.",
    },
    structuredData: {
        ...en.structuredData,
        abstract:
            "Australia's free online tool to download YouTube video thumbnails in HD quality",
        description:
            "Download YouTube video thumbnails in HD quality (1280x720). Australia's fastest thumbnail downloader - free and instant download.",
    },
};
